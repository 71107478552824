<div id="footer-wrapper" class="footer-wrapper">
  <div class="footer-bottom">
    <div class="container-fix">
      <div class="row">
        <div class="footer-list">
          <div class="footer-bottom-title">
            <a a [routerLink]="['/']" routerLinkActive="router-link-active">{{
              "GENERALS.HOME" | translate
            }}</a>
          </div>
          <div class="footer-bottom-list">
            <ul>
              <li>
                <a href="https://xpath.global/about/">{{
                  "GENERALS.ABOUT" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/about/media/">{{
                  "GENERALS.MEDIA" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/about/careers/">{{
                  "GENERALS.CAREERS" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/news/">{{
                  "GENERALS.NEWS" | translate
                }}</a>
              </li>
              <li>
                <a [routerLink]="['/']">{{
                  "GENERALS.GO-TO-MARKETPLACE" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/contact/">{{
                  "GENERALS.CONTACT" | translate
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-list">
          <div class="footer-bottom-title">
            <a href="https://xpath.global/service-providers/">{{
              "GENERALS.SERVICE-PROVIDERS" | translate
            }}</a>
          </div>
          <div class="footer-bottom-list">
            <ul>
              <li>
                <a href="https://xpath.global/service-providers/advantages/">{{
                  "GENERALS.ADVANTAGES" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/service-providers/products/">{{
                  "GENERALS.PRODUCTS" | translate
                }}</a>
              </li>
              <li class="footer-subtabs">
                <a
                  href="https://xpath.global/service-providers/products/marketplace/"
                  >{{ "GENERALS.MARKETPLACE" | translate }}</a
                >
              </li>
              <li class="footer-subtabs">
                <a
                  href="https://xpath.global/service-providers/products/erm/"
                  >{{ "GENERALS.ERM" | translate }}</a
                >
              </li>
              <li>
                <a href="https://xpath.global/service-providers/pricing/">{{
                  "GENERALS.PRICING" | translate
                }}</a>
              </li>
              <li>
                <a
                  href="https://xpath.global/service-providers/service-providers-faq/"
                  >{{ "GENERALS.FAQ" | translate }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-list">
          <div class="footer-bottom-title" id="gold-title">
            <a href="https://xpath.global/hr-companies/">{{
              "GENERALS.HR-GLOBAL-MOBILITY" | translate
            }}</a>
          </div>
          <div class="footer-bottom-list" id="footer-bottom-gold">
            <ul>
              <li>
                <a href="https://xpath.global/hr-companies/advantages/">{{
                  "GENERALS.ADVANTAGES" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/hr-companies/products/">{{
                  "GENERALS.PRODUCTS" | translate
                }}</a>
              </li>
              <li class="footer-subtabs">
                <a
                  href="https://xpath.global/hr-companies/products/marketplace/"
                  >{{ "GENERALS.MARKETPLACE" | translate }}</a
                >
              </li>
              <li class="footer-subtabs">
                <a href="https://xpath.global/hr-companies/products/erm/">{{
                  "GENERALS.ERM" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/hr-companies/pricing/">{{
                  "GENERALS.PRICING" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/hr-global-mobility-faq/">{{
                  "GENERALS.FAQ" | translate
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-list">
          <div class="footer-bottom-title" id="green-title">
            <a href="https://xpath.global/expats/">{{
              "GENERALS.EXPATRIATES" | translate
            }}</a>
          </div>
          <div class="footer-bottom-list" id="footer-bottom-green">
            <ul>
              <li>
                <a href="https://xpath.global/expats/advantages/">{{
                  "GENERALS.ADVANTAGES" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/expats/products/">{{
                  "GENERALS.PRODUCTS" | translate
                }}</a>
              </li>
              <li class="footer-subtabs">
                <a href="https://xpath.global/expats/products/marketplace/">{{
                  "GENERALS.MARKETPLACE" | translate
                }}</a>
              </li>
              <li class="footer-subtabs">
                <a href="https://xpath.global/expats/products/erm/">{{
                  "GENERALS.ERM" | translate
                }}</a>
              </li>
              <li class="footer-subtabs">
                <a href="https://xpath.global/expats/products/expat-app/">{{
                  "GENERALS.EXPAT-APP" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/expats/pricing/">{{
                  "GENERALS.PRICING" | translate
                }}</a>
              </li>
              <li>
                <a href="https://xpath.global/expats/expatriates-faq/">{{
                  "GENERALS.FAQ" | translate
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="!ercLandingpages" class="footer-list">
          <div class="footer-bottom-title">
            {{ "GENERALS.BECOME-EXPATS" | translate }}
          </div>
          <div class="footer-bottom-description">
            {{ "GENERALS.DONT-MISS" | translate }}
          </div>
          <div class="footer-bottom-action">
            <input
              type="text"
              name="email"
              placeholder="Your Email..."
              class="footer-control-form"
              [(ngModel)]="email"
            />
            <div class="btn footer-button" (click)="newsletterSubscribe()">
              {{ "MARKETPLACE.JOIN" | translate }}
            </div>
          </div>
        </div>
        <a *ngIf="ercLandingpages" class="w-30" [routerLink]="['/']">
          <img class="logo-footer" src="assets/images/logoYellow.svg" />
        </a>
      </div>
    </div>
  </div>
  <div class="footer-copy-right">
    <div class="container-fix">
      <div class="fl-l">
        Copyright © 2020 Xpath. All Rights Reserved.
        <a href="https://xpath.global/terms-and-conditions/"
          >Terms & Conditions.</a
        >
        <a href="https://xpath.global/privacy-policy/">Privacy Policy.</a>
        <a href="https://xpath.global/terms-of-use/gdpr/"
          >Privacy Policy for Job Applicants.</a
        >
      </div>
      <div class="fl-r">
        <a href="https://www.facebook.com/xpathglobal" class="footer-icons">
          <i class="fa fa-facebook-square" aria-hidden="true"></i>
        </a>
        <a href="https://twitter.com/xpath_global" class="footer-icons">
          <i class="icon-twitter"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/xpathglobal/"
          class="footer-icons"
        >
          <i class="icon-linkedin-squared"></i>
        </a>
        <a
          href="https://www.youtube.com/channel/UCk0y9_pPRCT88efTK2M4u_w"
          class="footer-icons"
        >
          <i class="fa fa-youtube-play" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>
</div>
