import { BackendHttpClient } from "@api/http/backend-http-client";
import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CurrentEntity } from "@api/entities/models/entity.model";
import { UserRequestData } from "@api/users/models/user.model";
import { EntityUserModel } from "@modules/shared/models/entity-user.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class ClientsService {
  private clientsUrl = "entities/clients";

  constructor(private readonly http: BackendHttpClient) {}

  /**
   * Get the details of the current client
   */
  public getCurrent(): Observable<CurrentEntity> {
    return this.http
      .get<any>(`${this.clientsUrl}/current`)
      .pipe(map((response) => response.result));
  }

  // GET Client
  getClient(id): Observable<any> {
    return this.http.get<any>(this.clientsUrl + "/" + id);
  }

  // PUT Edit Client info
  updateClientDetails(client): Observable<any> {
    return this.http
      .put<any>(this.clientsUrl + "/" + client.id, client, httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // PUT Edit Client account info
  updateClientAccountDetails(client): Observable<any> {
    return this.http
      .put<any>(
        this.clientsUrl + "/user/" + client.entity_id,
        client,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // When admin wants to login as client
  switchToClient(data): Observable<any> {
    return this.http.get<any>("switch-to-client/" + data.token).pipe(
      map((model) => {
        return model;
      })
    );
  }

  // Get Status transitions for provider
  getStatusTransitions(): Observable<any> {
    return this.http.get("entities/client/status-transitions");
  }

  updateStatus(statusId, entityId, reason = null): Observable<any> {
    const body = { status_id: statusId };
    if (reason) {
      body["reason"] = reason;
    }
    return this.http.patch("entities/providers/" + entityId, body, httpOptions);
  }

  getClientCountries(clientId): Observable<any> {
    return this.http.get<any>("entities/providers/" + clientId + "/countries");
  }

  public fetchUsers(
    clientId,
    params: UserRequestData
  ): Observable<EntityUserModel[]> {
    return this.http
      .get<any>(`entities/${clientId}/users`, {
        params: { ...params },
      })
      .pipe(map((response) => response.result?.items ?? []));
  }

  public getUsers(clientId, params: UserRequestData): Observable<any> {
    return this.fetchUsers(clientId, params).pipe(
      map((data) => data.map((item) => item.user))
    );
  }
}
